import React from 'react'
import '../components/styles/stability-assessment-calculator.scss'
import Layout from '../components/common/Layout'
import { useStaticQuery, graphql } from 'gatsby'

export default function Calculator() {

    const   data = useStaticQuery(graphql`
                query StabilityAssessment {
                allDataYaml(filter: {yamlId: {eq: "stability"}}) {
                    nodes {
                    results {
                        label
                        content
                    }
                    }
                }
                }
            `),
            handleSubmit = (e) => {
                e.preventDefault()
                const   formData = new FormData(e.target),
                        product = formData.get('products'),
                        unit = formData.get('unit'),
                        presentation = formData.get('presentations'),
                        parsedDays = parseFloat(formData.get('day')),
                        parsedHours = parseFloat(formData.get('hour')), 
                        parsedMin = parseFloat(formData.get('min')),
                        parsedDeg = parseFloat(formData.get('deg')),
                        passDays = !isNaN(parsedDays),
                        passHours = !isNaN(parsedHours),
                        passMin = !isNaN(parsedMin),
                        passProduct = product != null,
                        passDeg = !isNaN(parsedDeg),
                        passPresentation = presentation != null,
                        outputError = (input) => {
                            const $alert = document.querySelector('[data-input="' + input + '"]')
                            $alert.classList.add('show')
                            $alert.innerHTML = 'Required'
                        },
                        clearOutput = (input) => {
                            const $alert = document.querySelector('[data-input="' + input + '"]')
                            $alert.classList.remove('show')
                            $alert.innerHTML = ''
                        },
                        results = document.getElementById('results')
                
                clearOutput('days')
                clearOutput('hours')
                clearOutput('min')
                clearOutput('product')
                clearOutput('deg') 
                clearOutput('presentation') 
                results.innerHTML = ''

                if( passProduct
                    && passDeg
                    && passPresentation
                    && passDays
                    && passHours
                    && passMin){

                    const   days = parsedDays * 24,
                            hours = parsedHours,
                            mins =  parsedMin / 60,
                            convertedDegrees = unit === 'f' ? parsedDeg : (parsedDeg * 9/5) + 32,
                            totalHours = days + hours + mins,
                            isRed = convertedDegrees >= 78 || totalHours > 72,
                            isOrange = convertedDegrees < 36,
                            isLightGreen =  convertedDegrees >= 47
                                            && convertedDegrees <= 77
                                            && totalHours <= 72,
                            contentLabel =  isRed ? 'red'
                                            : isOrange ? 'orange'
                                            : isLightGreen ? 'light-green'
                                            : 'dark-green',
                            content = data.allDataYaml.nodes[0].results.filter(obj => obj.label === contentLabel)[0].content
  
                    results.innerHTML = `<p class="label">Stability information</p><p>${content}</p>`
                }
                else{
                    !passDays && outputError('days')
                    !passHours && outputError('hours')
                    !passMin && outputError('min')
                    !passProduct && outputError('product')
                    !passDeg && outputError('deg') 
                    !passPresentation && outputError('presentation') 
                }
            },
            handleSelect = (e) => {
                e.target.value === 'besremi' && (document.getElementById('presentations').value = 'syringe')
            }

    return (    
        <Layout active='stability-assessment-calculator'
        title='PharmaEssentia Medical Affairs | Stability Assessment Calculator'
        description='A tool designed to offer quick stability information about the most common temperature excursions for our approved products.'
        socialTitle='PharmaEssentia Medical Affairs | Stability Assessment Calculator'
        socialDescription='A tool designed to offer quick stability information about the most common temperature excursions for our approved products.'
        pathname='/medical-information/stability-assessment-calculator'
        >
            <section className='col margin-padding-zero align-center'>
                <div className='inner'>
                    <h1>Stability Assessment Calculator</h1>
                    <p>The stability assessment calculator is a tool for healthcare professionals that offers a quick stability assessment based on the unique conditions for the specific temperature excursion that occurred for ropeginterferon alfa-2b-njft.</p>
                    <p>The assessment is based on data gathered for a single temperature excursion. Please contact the PharmaEssentia Medical Information team if you suspect the product to have experienced multiple temperature excursions.</p>
                    <p>Please contact the PharmaEssentia Medical Information team for any temperature excursions that occur during shipping or transport.</p>
                    <p className='bold'>Please complete the form below.</p>
                </div>
                <div id='stability-assessment-calculator'>
                    <div className='inner'>
                        <div className='wrapper row'>
                            <form onSubmit={handleSubmit} className='col'>
                                <div className='input-grp'>
                                    <p className='label'>Select a product*</p>
                                    <div className='col alert-required'>
                                        <div className='select-wrapper'>
                                            <select id='products' name='products' defaultValue={'DEFAULT'} onChange={handleSelect}>
                                                <option value='DEFAULT' disabled>Select an option</option>
                                                <option value='besremi'>BESREMi (ropeginterferon alfa-2b-njft)</option>
                                            </select>
                                            <span className='triangle-wrapper'>
                                                <span className='triangle'></span>
                                            </span>
                                        </div>
                                        <p className="alert" data-input="product"></p>
                                    </div>
                                    
                                </div>
                                <div className='input-grp'>
                                    <p className='label'>To what temperature was the product exposed?*</p>
                                    <div className='col alert-required'>
                                        <div className='row deg-wrapper'>
                                            <div className='row deg'>
                                                <input type='number' name='deg' id='deg'/>
                                                <p>&deg;</p>
                                            </div>
                                            <div className='row align-center'>
                                                <input type='radio' id='html' name='unit' value='f' defaultChecked='checked'/>
                                                <p>Fahrenheit</p>
                                                <input type='radio' id='css' name='unit' value='c'/>
                                                <p>Celsius</p>
                                            </div>
                                        </div>
                                        <p className="alert" data-input="deg"></p>
                                    </div>
                                </div>
                                <div className='input-grp'>
                                    <p className='label'>Select the product presentation</p>
                                    <div className='col alert-required'>
                                        <div className='select-wrapper'>
                                            <select id='presentations' name='presentations' defaultValue={'DEFAULT'}>
                                                <option value='DEFAULT' disabled>Select an option</option>
                                                <option value='syringe'>Syringe</option>
                                            </select>
                                            <span className='triangle-wrapper'>
                                                <span className='triangle'></span>
                                            </span>
                                        </div>
                                        <p className="alert" data-input="presentation"></p>
                                    </div>
                                </div>
                                <div className='input-grp days-hours-min'>
                                    <p className='label'>How long was the product exposed to this temperature?*</p>
                                    <div className='row'>
                                        <div className='col alert-required'>
                                            <p>Days</p>
                                            <input type='number' name='day' id='days' placeholder='0.0'/>
                                            <p className="alert" data-input="days"></p>
                                        </div>
                                        <div className='col alert-required'>
                                            <p>Hours</p>
                                            <input type='number' name='hour' id='hours' placeholder='0.0'/>
                                            <p className="alert" data-input="hours"></p>
                                        </div>
                                        <div className='col alert-required'>
                                            <p>Min(s)</p>
                                            <input type='number' name='min' id='min' placeholder='0.0'/>
                                            <p className="alert" data-input="min"></p>
                                        </div>
                                        <div className='col alert-required submit-wrapper'>
                                            <p>*</p>
                                            <input type='submit' value='submit' />
                                        </div>
                                    </div>
                                </div>
                                <p className='sml'>*Required fields.</p>
                            </form>
                            <div id='results' className='col'>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}